import { StorageService } from './services/storage';

$(document).ready(function () {
  const validZip = new RegExp('^\\s*(\\d{5})\\s*$');
  const storageService = new StorageService();

  /* The following script binds into search button in About Page */
  $('#findNearLab-btn').click(function () {
    $('#validzc').addClass('hide');

    let zipCode = $('#widget-zipCode').val();

    if (validZip.test(zipCode)) {
      storageService.zipCode = zipCode;
      location.href = '/std-test-center.php';
    } else {
      $('#validzc').removeClass('hide');
    }
  });

  /* The following script binds to the findalab search button on the Home page */
  $('#findalab-btn').on('click', function () {
    if (Object.keys(storageService.psc).length > 0) {
      return (window.location.href = '/std-test-center.php');
    }

    const zipCode = $('#findalab-input').val();

    if (validZip.test(zipCode)) {
      storageService.zipCode = zipCode;
      location.href = '/std-test-center.php';
    } else {
      $('#invalid-zip-code-error').removeClass('hide');
    }
  });
});
